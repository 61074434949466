<template>
<main class="main">
        <div class="loading" v-if="azureloader">Loading&#8230;</div>    
    <!-- <nav class="breadcrumb-nav border-0 mb-0">
            <div class="container">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <nuxt-link to="/">Home</nuxt-link>
                    </li>
                    <li class="breadcrumb-item">
                        <a href="javascript:;">Pages</a>
                    </li>
                    <li class="breadcrumb-item active">Login</li>
                </ol>
            </div>
        </nav> -->

    <div class="login-page  pt-6 pb-6  pt-lg-17 pb-lg-17" >
        <!-- v-lazy:background-image="merchantDetails.merchant_imageurl" -->
        <div class="container">
            <div class="form-box" :style="{ paddingBottom: customerSetting.customer_setting.azure_AD_b2C === 'A' ? '5rem;' : '0.8rem;' }">
                <div class="form-tab">
                    <tabs class="nav-pills nav-fill" :data="tabsData"></tabs>
                    <div class="tab-content">
                        <!-- --------sign_in form--------- -->
                        <div class="tab-pane fade show active" id="sign_in">
                            <form action="#" >
                                <div v-if="customerSetting.customer_setting.azure_only === 'I'">
                                <b-form-group v-slot="{ ariaDescribedby }"
                                        style="font-family: Futura; margin-top: 1em">
                                        <b-form-radio-group v-model="logintype" :options="radiooptions"
                                            :aria-describedby="ariaDescribedby" name="radio-inline">
                                        </b-form-radio-group>
                                    </b-form-group>
                                <div class="form-group">
                                    <label for="singin-email-2" v-if="logintype === 'email'">{{$t("page.login.email")}}</label>
                                    <label for="singin-email-2" v-else>{{$t("page.login.mobile")}}</label>
                                    <input v-if="logintype === 'email'" type="text" class="form-control" id="singin-email-2" name="singin-email" v-model.trim="$v.username.$model" :class="{ 'form-control--error': $v.username.$error }" />
                                    <div v-if="this.$v.username.$dirty && logintype !== 'mobile'">
                                        <div class="error-txt" v-if="!$v.username.required">
                                            {{$t('page.login.email_is_required')}}
                                        </div>
                                        <span class="error-txt" v-if="$v.username.required">
                                            <p class="error-txt" v-if="!$v.username.validemail">
                                                {{$t('page.login.email_is_invalid')}}
                                            </p>
                                        </span>
                                    </div>
                                    <b-form-group v-if="logintype === 'mobile'">
                                        <vue-tel-input class="inputbox" placeholder="" :class="{ 'form-control--error': $v.username.$error }" style="
                                                    height: 2.75em;
                                                    margin-bottom: 0;
                                                    text-align: center;
                                                    " :enabledCountryCode="true" id="phone" :mode="'international'" @validate="changeInput" v-model="username"></vue-tel-input>
                                        <div v-if="this.$v.username.$dirty && logintype !== 'email'">
                                            <div class="error-txt" v-if="!$v.username.required">
                                                {{ $t("page.login.mobile_is_required") }}
                                            </div>
                                            <span class="error-txt" v-if="$v.username.required">
                                                <p class="error-txt" v-if="!$v.username.validphone">
                                                    {{$t('page.login.mobile_is_invalid')}}
                                                </p>
                                            </span>
                                        </div>
                                    </b-form-group>
                                </div>

                                <div class="form-group">
                                    <label for="singin-password-2">{{$t('page.login.password')}}</label>
                                    <input type="password" autoComplete='new-password' class="form-control" id="singin-password-2" name="singin-password" v-model.trim="$v.password.$model" :class="{ 'form-control--error': $v.password.$error }" />
                                    <div v-if="this.$v.password.$dirty">
                                        <div class="error-txt" v-if="!$v.password.required">
                                            {{$t('page.login.password_is_required')}}
                                        </div>
                                    </div>
                                </div>

                                <div class="form-footer" style="border-bottom:none;">
                                    <button type="submit" class="btn btn-outline-primary-2" @click.prevent="loginUser()">
                                        <span>{{$t('page.login.log_in')}}</span>
                                        <!-- <i class="icon-long-arrow-right"></i> -->
                                    </button>

                                    <!-- <div class="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                class="custom-control-input"
                                                id="signin-remember-2"
                                                v-model="rememberme"
                                            />
                                            <label
                                                class="custom-control-label"
                                                for="signin-remember-2"
                                            >Remember Me</label>
                                        </div> -->

                                        <a href="javascript:;" @click="goForgot()"
                                        class="forgot-link">{{$t('page.login.forgot_your_password')}}</a>
                                </div>
                                </div>
                                <div class="divider" v-if="customerSetting.customer_setting.azure_AD_b2C === 'A' && customerSetting.customer_setting.azure_only === 'I'">
                                    <span>{{ $t('page.login.or_continue_with') }}</span>
                                    </div>
                                   <!-- <div v-if="customerSetting.customer_setting.azure_AD_b2C === 'A'" class="google-button" @click.prevent="loginWithProvider('B2C_1_signingoogle')"><img src="/images/google.svg" alt="" style="display: inline;height: 30px;"> Google</div> -->
                                    <br>
                                    <!-- <div  class="google-button" @click.prevent="loginWithProvider('facebook')"><img src="/images/facebook.svg" alt="" style="display: inline;height: 30px;">  Facebook</div>
                                <br>
                                <div  class="google-button" @click.prevent="loginWithProvider('microsoft')"><img src="/images/microsoft.svg" alt="" style="display: inline;height: 30px;"> Microsoft Account</div>
                                <br>
                                <div  class="google-button" @click.prevent="loginWithProvider('apple')"><img src="/images/apple.svg" alt="" style="display: inline;height: 30px;"> Apple</div>
                                <br>
                                <div  class="google-button" @click.prevent="loginWithProvider('localemail')"> Email</div>
                                <br> -->
                                    <div v-if="customerSetting.customer_setting.azure_AD_b2C === 'A'" class="google-button" @click.prevent="loginWithProvider(userFlow)"> {{ $t('page.login.phone') }}</div>
                            </form>
                            <!-- <div class="form-choice">
                                    <p class="text-center">or sign in with</p>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <a href="javascript:;" class="btn btn-login btn-g">
                                                <i class="icon-google"></i>
                                                Login With Google
                                            </a>
                                        </div>

                                        <div class="col-sm-6">
                                            <a href="javascript:;" class="btn btn-login btn-f">
                                                <i class="icon-facebook-f"></i>
                                                Login With Facebook
                                            </a>
                                        </div>
                                    </div>
                                </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';
import parsePhoneNumber from "libphonenumber-js";
var validator = require("validator");
import * as moment from 'moment-timezone';
import axios from "axios";
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, maxLength, between, sameAs, integer, email, numeric, helpers } from 'vuelidate/lib/validators';
import Tabs from '~/components/elements/Tabs';
const validemail = helpers.regex("validemail", /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/);
import crypto from "crypto";
// const validphone = helpers.regex('validphone', /^(\+[\d]{1,5}|0)?[7-9]\d{9}$/);

function decrypt(messagebase64, keyBase64, ivBase64) {
    const key = Buffer.from(keyBase64, 'base64');
    const iv = Buffer.from(ivBase64, 'base64');
    const decipher = crypto.createDecipheriv(getAlgorithm(key), key, iv);
    let decrypted = decipher.update(messagebase64, 'hex');
    decrypted += decipher.final('utf8');
    return decrypted;
}

function encrypt(plainText) {
    console.log("plainText@@@", plainText);
    
    var keyBase64 = "YVc1bWIyMWxlbnB2Wm5samIzVndiMj09eiyOLtzOoSh=";
    var ivBase64 = 'aW5mb21lenpvZnljb3Vwb2==';

    const key = Buffer.from(keyBase64, 'base64');
    const iv = Buffer.from(ivBase64, 'base64');

    const cipher = crypto.createCipheriv(getAlgorithm(keyBase64), key, iv);
    let encrypted = cipher.update(plainText, 'utf8', 'hex')
    encrypted += cipher.final('hex');
    console.log("encrypted@@@", encrypted);
    
    return encrypted;
};

function getAlgorithm(key) {
    // var key = Buffer.from(keyBase64, 'base64');
    console.log("Key value", key, key.length)
    switch (key.length) {
        case 16:
            return 'aes-128-cbc';
        case 32:
            return 'aes-256-cbc';
        case 44:
            return 'aes-256-cbc';
    }
    throw new Error('Invalid key length: ' + key.length);
}

const validphone = (value) => {
    const phoneNumber = parsePhoneNumber(value.toString());
    console.log("Phone Number ###", phoneNumber, value)
    if (phoneNumber) {
        console.log("Phone Number*****", phoneNumber.isValid())
        if (phoneNumber.isValid())
            return true
        else
            return false
    } else {
        return false
    }
}

export default {
    mixins: [validationMixin],
    layout: 'layoutg',
    components: {
        Tabs
    },
    async asyncData({ params, query, env, $axios, error, req, store, $t, app: { i18n } }) {
        const async_locale = i18n.locale;
        if (process.server) {
            var data = {
                username: process.env.AUTH_NAME,
                password: process.env.AUTH_KEY,
            }
            console.log("Process Server", req.headers, `http://${req.headers.host}/api/mzapps/merchant`);
            if (query.catalogue_code !== 'null' && query.catalogue_code !== 'undefined' && query.catalogue_code !== '.env') {
                var tokenURL = `http://${req.headers.host}/api/mzapps/token`;
                var requestURL = `http://${req.headers.host}/api/mzapps/merchant?timestamp=${new Date().getTime()}`;
                var settingURL = `http://${req.headers.host}/api/mzapps/settings?timestamp=${new Date().getTime()}`;
                var customersettingURL = `http://${req.headers.host}/api/mzapps/customersetting?timestamp=${new Date().getTime()}`
                if (req.headers.orghost) {
                    tokenURL = `https://${req.headers.orghost}/api/mzapps/token`;
                    requestURL = `https://${req.headers.orghost}/api/mzapps/merchant?timestamp=${new Date().getTime()}`;
                    settingURL = `https://${req.headers.orghost}/api/mzapps/settings?timestamp=${new Date().getTime()}`;
                    var customersettingURL = `http://${req.headers.orghost}/api/mzapps/customersetting?timestamp=${new Date().getTime()}`
                }
                var response, settingRes , customersettingRes, token;

                try {
                    token = await $axios.post(tokenURL,data);
                    $axios.setToken(token.data.token, 'Bearer')
                    response = await $axios.get(requestURL);
                    settingRes = await $axios.get(settingURL);
                    customersettingRes = await $axios.get(customersettingURL);
                    console.log("customersettingRes@@", customersettingRes.data);
                    console.log("GET camapignRes setting ###", settingRes.data)
                    if (customersettingRes.data.customer_setting.secure_status === 'I') {
                         error({ statusCode: 404, message: 'Post not found' })
                    } 
                    return {
                        merchantSettings: settingRes.data,
                        merchantDetails: response.data.merchant,
                        customerSetting : customersettingRes.data,
                        async_locale: async_locale,
                        environment: process.env.ENV,
                        token: token.data.token

                    };
                } catch (e) {
                    console.log('errorrrrrrr###', e)
                    error({ statusCode: 404, message: 'Post not found' })
                    return {
                        merchantDetails: null,
                        merchantSettings: null,
                        customerSetting:null,
                        async_locale: async_locale,
                        environment: process.env.ENV,
                        token: null
                    };
                }
            }
            // else {
            //   return {
            //     merchantDetails: '',
            //     kakao_key: process.env.KAKAO_KEY,
            //     event_id: params.event_id,
            //     country: params.country
            //   };
            // }

        }
    },
    data: function () {
        return {
            tabsData: [{
                id: 'sign_in',
                title: `${this.$t('page.login.welcome_signIn')} ${this.merchantDetails ? this.merchantDetails.merchant_name : ''} <br> ${this.$t('page.login.digital_wallet')} ${this.$t('page.login.please_login')}`,
                active: true
            }, ],
            radiooptions: [
                { text: "Email", value: "email" },
                { text: "Mobile", value: "mobile" },
            ],
            logintype: "email",
            username: '',
            password: '',
            customer_id: '',
            welcomeMessage: '',
            authInProgress: false,
            userFlow: 'B2C_1_Coupon_signin',
            msal: null,
            azureloader:true ,           
            lastFetchedTime:null 

        };
    },

    head() {
        return {
            title: `${this.$t('components.user_menu.coupon_wallet')} | ${this.merchantDetails.merchant_name}`,
            meta: [
                { hid: "robots", name: "robots", content: "noindex" },
                // {
                //     name: "viewport",
                //     content:
                //     "width:device-width, initial-scale:1.0, maximum-scale:1.0, user-scalable=no",
                // },
                {
                    name: 'viewport',
                    content: 'width=device-width, initial-scale=1'
                },

                {
                    property: "og:image",
                    content: `${this.merchantDetails.merchant_logourl}`,
                    vmid: "og:image",
                },

            ],
            link: [
                { rel: "apple-touch-icon", sizes: "128x128", href: `${this.merchantDetails.merchant_logourl}` },
                { rel: 'icon', type: 'image/x-icon', href: `${this.merchantDetails.merchant_logourl}` },
                { rel: "apple-touch-icon", sizes: "128x128", href: `${this.merchantDetails.merchant_logourl}` },
            ],
            script: [
            { src: "/js/jquery.js", async: true },
          { id: "cookieyes",src: "/js/cookieyes.js",body: true},
          { src: "https://www.google-analytics.com/analytics.js", async: true },
          { src: "https://www.googletagmanager.com/gtag/js?id=G-XY60PRETXZ", async: true },
          { src: "/js/gtag.js", async: true },
                {
                    innerHTML: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
                    ga('create', 'UA-96639441-1', 'auto')
                    ga('send', 'pageview')
                    ga('create', '${this.merchantSettings.analytic_id}', 'auto', '${this.merchantSettings.analytic_name}');
                    ga('${this.merchantSettings.analytic_name}.send', 'pageview');
                    `,
                    type: 'text/javascript',
                    charset: 'utf-8'
                },
            ],

        };
    },

    validations: {
        username: {
            required,
            validemail(value) {
                return (this.logintype === 'email') ? validemail(value) : true;
            },
            validphone(value) {
                return (this.logintype !== 'email') ? validphone(value) : true;
            },
            // validphone
        },
        password: {
            required
        },

    },

    watch: {
        customerEmail: function (val) {
            this.customerEmail = val.toLowerCase();
        },
        customerPhone: function (val) {
            var phoneNumber = parsePhoneNumber(val.toString());
            if (phoneNumber) {
                console.log("Phone Number ###", phoneNumber.isValid())
                if (phoneNumber.isValid()) {
                    console.log("Value country code### ", phoneNumber.country);
                    this.codeCountry = phoneNumber.country;
                    this.natinalNumber = phoneNumber.nationalNumber;
                }
            }
        },
        // <============ watch function for customer Dob ==============>
        customerDOB: function (val) {

            var date = moment(val).format("MMM-DD");
            this.customer_dob = date;
            console.log('customerDOB', this.customer_dob);
            // var arr1 = val.split(" ",3);
            // // this.customer_dob =(arr1[4]+'-'+arr1[9]);
            // console.log('date',arr1);
        },
    },

    methods: {
        
        generateNewToken: async function () {
      var data = {
          username: 'd0cb2a18b1d8ac9be9b7fb73099bed02',
          password: '3c7c5736e2af15f0772d0c76f28f34f5',
      }
      var url = `/api/mzapps/newtoken`;
      await this.$axios.$post(url,data).then(response => {
          console.log("Token response", response);
          this.lastFetchedTime = new Date().toLocaleTimeString();
          this.$axios.setToken(response.token, 'Bearer')

      }).catch(error => {
          if (error.data) {
          faro.api.pushError(new Error(error.data.message));
          } else {
          faro.api.pushError(new Error(error));
          }
      })
      },
      getNextInterval() {
      const now = new Date();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      const milliseconds = now.getMilliseconds();

      // Calculate the minutes remaining for the next 10-minute mark
      const minutesToNext = 9 - (minutes % 9);
      const timeToNextCall = (minutesToNext * 60 * 1000) - (seconds * 1000) - milliseconds;

      // const timeToNextCall = (60 * 1000) - (seconds * 1000) - milliseconds;

      console.log(`[${now.toLocaleTimeString()}] Next API call in ${timeToNextCall / 1000} seconds`);
      return timeToNextCall;
      },

      startPolling() {
      setTimeout(() => {
          this.generateNewToken();
          setInterval(this.generateNewToken, 540000); // Continue calling every 10 minutes
      }, this.getNextInterval());
      },
        faroLog(data) {
            var message = "application:" + "Mz-WALLET, environment:" + `${this.environment}` + ", level:" + `${data.level}` + ", module:" + `CartMenu Page (${data.api})` + ", msg:" + `${data.msg}` + ", time:" + `${new Date()}`
            return message
        },
        changeInput: function (val) {
            console.log(val);
            if (val.isValid) {
                this.customerPhone = val.number.international
            }
        },

        isAccountComplete(account) {
            return account && account.tenantId && account.username;
        },

        async loginWithProvider(providerName) {

                try {
                    // Initialize MSAL with dynamic settings from CustomerSetting
                    console.log('CustomerSetting', this.customerSetting);
                    var CustomerSetting = this.customerSetting.customer_setting

                    if (!this.msal) {
                    console.error('MSAL instance is not available');
                    return;
                    }

                    const accounts = this.msal.getAllAccounts();
                    if (this.authInProgress || (accounts.length > 0 && this.isAccountComplete(accounts[0]))) {
                    console.log("Authentication is already in progress or user is already logged in. Please wait.");
                    return;
                    }
                    this.authInProgress = true;
                    const stateData = { redirectUrl: CustomerSetting.azure_redirect_url }; // Use dynamic redirect URI
                    const loginRequest = {
                    scopes: ['openid', 'profile'],
                    prompt: 'login',
                    authority: `https://${CustomerSetting.tenant_name}.b2clogin.com/${CustomerSetting.tenant_name}.onmicrosoft.com/${providerName}`, // Dynamic authority
                    state: btoa(JSON.stringify(stateData)), // Encode state data
                    };
                    
                    await this.msal.loginRedirect(loginRequest);
                    
                    this.$store.commit("sessionStorage/assignuserLoginSuccessfull", login_successfull);
                } catch (error) {
                    console.error('Error during login:', error);
                } finally {
                    this.authInProgress = false;
                }
            

            
        },

        // <----------- Azure AD B2c Login Api ---------->

        // AzureSso: async function () {
        //     try {
        //         if (!this.$msal) {
        //         console.error('MSAL instance is not available');
        //         return;
        //         }

        //         const accounts = this.$msal.getAllAccounts();
        //         if (this.authInProgress || (accounts.length > 0 && this.isAccountComplete(accounts[0]))) {
        //         console.log("Authentication is already in progress or user is already logged in. Please wait.");
        //         return;
        //         }

        //         this.authInProgress = true;
        //         const stateData = { redirectUrl: 'https://8qqh.walletdev.mezzofy.com/loading' };
        //         const loginRequest = {
        //         scopes: ['openid', 'profile'],
        //         state: btoa(JSON.stringify(stateData)), // Encode state data
        //         };

        //         await this.$msal.loginRedirect(loginRequest);
        //     } catch (error) {
        //         console.error('Error during login:', error);
        //     } finally {
        //         this.authInProgress = false;
        //     }
        // },

        // <----------- Login Api ---------->

        loginUser: async function () {
            this.$v.$touch();
            if (this.$v.username.$invalid && this.$v.password.$invalid) {
                // alert('Enter all data')
            } else {
                var email = this.username;
                var password = this.password;

                if (email && password) {
                    if (this.logintype === "email") {
                        if (validator.isEmail(this.username)) {
                            var logindata = {
                                customer: {
                                    customer_email: this.username,
                                    customer_password: this.password,
                                },
                            };
                        } else {
                            alert(this.$t('page.alerts.invalid_email'))
                            //   alert("invalid email");
                        }
                    } else {

                        console.log("username", this.username);
                        var phoneNumber = parsePhoneNumber(this.username.toString());
                        if (phoneNumber) {
                            console.log("Phone Number ###", phoneNumber.isValid());
                            if (phoneNumber.isValid()) {
                                console.log("Value country code### ", phoneNumber);
                                var logindata = {
                                    customer: {
                                        customer_mobile: phoneNumber.number,
                                        customer_password: this.password,
                                    },
                                };
                            }
                        } else {
                            alert(this.$t('page.alerts.invalid_phone'))
                            //   alert("invalid Phone");
                        }
                    }

                    if (logindata) {
                        console.log("logindata", logindata);
                        await this.$axios
                            .$post(`/api/mzapps/wallet/emailLogin`, logindata)
                            .then((response) => {
                                console.log("loginresponse", response);
                                if (response) {
                                    this.customer_id  = response
                                    this.$store.commit("sessionStorage/assigncustomerID", this.customer_id);

                                    // this.$store.commit("sessionStorage/assignuserDetails", response.customer);
                                    // console.log('user_details', this.$store.state.sessionStorage.user_details)
                                    // var login_status = true;
                                    var login_successfull = true;
                                    this.$store.commit("sessionStorage/assignLoginCustomerId", this.customer_id);

                                    this.$store.commit("sessionStorage/assignuserLoginSuccessfull", login_successfull);
                                    this.$store.commit("sessionStorage/assignGenericLogin", login_successfull);
                                    // var encrypted = this.$route.query.encrypted
                                    // var encrypt_customer_id = this.$route.query.customer_id;
                                    var url = `/${this.customer_id}/shop/sidebar/list?lang=${this.async_locale}`
                                   
                                    window.location.replace(url)

                                } else {
                                    alert("Customer login failed...");
                                    // window.location.replace(`/${this.catalogue_code}`);
                                }
                            })
                            .catch((e) => {
                                console.log("Login Error ", e);
                                // alert ("Please check your email/mobile and/or password.");
                                alert(this.$t('page.alerts.terms'))
                                var error_params = {
                                    msg: e.data ? e.data.message : e,
                                    api: 'sentOTP Api',
                                    level: 'error'
                                }
                                var error_text = this.faroLog(error_params)
                                faro.api.pushError(new Error(error_text))
                            });
                    } else {}
                } else {
                    alert("Please Enter Valid Username and Password");
                }
            }
        },

        // <----------- forgot Api ---------->

        goForgot() {
            this.$store.commit("sessionStorage/assignAzure", true);
            var url = `/forgot`
            console.log("url", url);
            window.location.replace(url)
        },

        changeInput: function (val) {
            console.log(val);
        },

        changeTheme(primary) {
            var r = document.querySelector(':root');
            r.style.setProperty('--primaryColor', primary);
        },

        // tabData(){

        //     var select_lang = this.$store.state.sessionStorage.select_lang;
        //     var default_code = this.merchantSettings.default_lang;
        //     this.$i18n.locale = select_lang ? select_lang : default_code ;
        //     // this.$store.commit('sessionStorage/assignLang', this.$i18n.locale);
        //     var returnData = [
        //         {
        //             id: 'sign_in',
        //             title: this.$t('page.login.sign_in'),
        //             active: true
        //         },
        //         {
        //             id: 'register',
        //             title: this.$t('page.login.register')
        //         }
        //     ]

        //     if (this.ctalogueDetails.allow_member_signup !== 'A') {
        //             var returnData = [
        //             {
        //                 id: 'sign_in',
        //                 title: this.$t('page.login.sign_in'),
        //                 active: true
        //             },

        //         ]
        //     }

        //     return returnData

        // }

        //<---------------- decrypt customerId -------------------->
        decryptCustomerId(customer_id) {
            var data = customer_id
            var keyBase64 = "YVc1bWIyMWxlbnB2Wm5samIzVndiMj09eiyOLtzOoSh=";
            var ivBase64 = 'aW5mb21lenpvZnljb3Vwb2==';
            console.log("data##", data);
            var decryptedCipherText = decrypt(data, keyBase64, ivBase64);
            console.log("decryptedCipherText", decryptedCipherText);
            this.customer_id = decryptedCipherText;
            console.log(' this.customer_id@@@ ', this.customer_id);
        },

        async GenericLogin(id) {
        var data = {
            "customer":{
                "external_id":id,
                "lang":"en"
            }
        }
        var url = `/api/mzapps/wallet/genericlogin?timestamp=${new Date().getTime()}`;
        await this.$axios.$post(url,data).then(response => {
            console.log('response%%%',response);
            this.$store.commit("sessionStorage/assignuserLoginSuccessfull", true);
            this.$store.commit("sessionStorage/assignAzureGenericLogin", true);
            var customerId = response.customer.customer_id
            this.$store.commit("sessionStorage/assigncustomerID", customerId);
            this.$store.commit("sessionStorage/assignLoginCustomerId", customerId);
            var url = `/${customerId}/shop/sidebar/list?lang=en`
            window.location.replace(url)

        }).catch(e => {
            alert(e.data ? e.data.message : 'User Not Found')
            var error_params = {
                msg: e.data ? e.data.message : e,
                api: 'Customer details Api',
                level: 'error'
            }
            var error_text = this.faroLog(error_params)
            faro.api.pushError(new Error(error_text))
        })
        },

        msalConfig(CustomerSetting) {
            if (CustomerSetting) {
                const msalConfig = {
                auth: {
                    clientId: CustomerSetting.client_id,
                    authority: `https://${CustomerSetting.tenant_name}.b2clogin.com/${CustomerSetting.tenant_name}.onmicrosoft.com/${CustomerSetting.user_flow_name}`,
                    knownAuthorities: [`${CustomerSetting.tenant_name}.b2clogin.com`],
                    redirectUri: CustomerSetting.azure_redirect_url //CustomerSetting.azure_redirect_url,
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: true,
                },
                };

                const msalInstance = new PublicClientApplication(msalConfig);
                console.log('MSAL instance directly in component:', msalInstance);
                this.msal = msalInstance;
            } else {
                console.error('CustomerSetting is not available');
            }
        },


        // <<<<<<<<<<<<<<<<<<<<<<<<<< multi language Function >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        MultiLang: function (lang_code) {
            // console.log('lang_code',lang_code);
            var lang_code = lang_code;
            this.$store.commit('sessionStorage/assignSelectLang', lang_code);

            // MultiLang for Merchant 

            if (this.merchantDetails.localization !== undefined && this.merchantDetails.localization !== null) {
                if (lang_code === 'en') {
                    if ((this.merchantDetails.localization.merchant_name !== null && this.merchantDetails.localization.merchant_name !== undefined) && (this.merchantDetails.localization.merchant_name.en !== null && this.merchantDetails.localization.merchant_name.en !== undefined)) {
                        this.merchantDetails.merchant_name = this.merchantDetails.localization.merchant_name.en ? this.merchantDetails.localization.merchant_name.en : this.merchantDetails.merchant_name
                    }
                }
                if (lang_code === 'id') {
                    if ((this.merchantDetails.localization.merchant_name !== null && this.merchantDetails.localization.merchant_name !== undefined) && (this.merchantDetails.localization.merchant_name.id !== null && this.merchantDetails.localization.merchant_name.id !== undefined)) {
                        this.merchantDetails.merchant_name = this.merchantDetails.localization.merchant_name.id ? this.merchantDetails.localization.merchant_name.id : this.merchantDetails.merchant_name
                    }
                }
                if (lang_code === 'ja') {
                    if ((this.merchantDetails.localization.merchant_name !== null && this.merchantDetails.localization.merchant_name !== undefined) && (this.merchantDetails.localization.merchant_name.ja !== null && this.merchantDetails.localization.merchant_name.ja !== undefined)) {
                        this.merchantDetails.merchant_name = this.merchantDetails.localization.merchant_name.ja ? this.merchantDetails.localization.merchant_name.ja : this.merchantDetails.merchant_name
                    }
                }
                if (lang_code === 'kr') {
                    if ((this.merchantDetails.localization.merchant_name !== null && this.merchantDetails.localization.merchant_name !== undefined) && (this.merchantDetails.localization.merchant_name.kr !== null && this.merchantDetails.localization.merchant_name.kr !== undefined)) {
                        this.merchantDetails.merchant_name = this.merchantDetails.localization.merchant_name.kr ? this.merchantDetails.localization.merchant_name.kr : this.merchantDetails.merchant_name

                    }
                }
                if (lang_code === 'th') {
                    if ((this.merchantDetails.localization.merchant_name !== null && this.merchantDetails.localization.merchant_name !== undefined) && (this.merchantDetails.localization.merchant_name.th !== null && this.merchantDetails.localization.merchant_name.th !== undefined)) {
                        this.merchantDetails.merchant_name = this.merchantDetails.localization.merchant_name.th ? this.merchantDetails.localization.merchant_name.th : this.merchantDetails.merchant_name
                    }
                }
                if (lang_code === 'zh_Hans') {
                    if ((this.merchantDetails.localization.merchant_name !== null && this.merchantDetails.localization.merchant_name !== undefined) && (this.merchantDetails.localization.merchant_name.zh_Hans !== null && this.merchantDetails.localization.merchant_name.zh_Hans !== undefined)) {
                        this.merchantDetails.merchant_name = this.merchantDetails.localization.merchant_name.zh_Hans ? this.merchantDetails.localization.merchant_name.zh_Hans : this.merchantDetails.merchant_name
                    }
                }
                if (lang_code === 'zh_Hant') {
                    if ((this.merchantDetails.localization.merchant_name !== null && this.merchantDetails.localization.merchant_name !== undefined) && (this.merchantDetails.localization.merchant_name.zh_Hant !== null && this.merchantDetails.localization.merchant_name.zh_Hant !== undefined)) {
                        this.merchantDetails.merchant_name = this.merchantDetails.localization.merchant_name.zh_Hant ? this.merchantDetails.localization.merchant_name.zh_Hant : this.merchantDetails.merchant_name
                    }
                }
            }
        },

    },

    created: function () {
        this.$axios.setToken(this.token, 'Bearer')
    },

    mounted: async function () {
        this.startPolling();
        

        if (this.customerSetting.customer_setting.secure_status !== 'A') {
            this.azureloader = false
        }
        
        console.log('this.merchantDetails',this.merchantDetails);
        
        if (this.merchantDetails.merchant_id === '5C1U' || this.merchantDetails.merchant_id === 'BWTI') {
            this.userFlow = 'B2C_1_Coupon_signin'
        } else if (this.merchantDetails.merchant_id === '8QQH') {
            this.userFlow = 'B2C_1_signinlocalphone'
        } else {
            this.userFlow = 'B2C_1_Coupon_signin'
        }

        if (this.customerSetting) {
            await this.msalConfig(this.customerSetting.customer_setting)
        }

        console.log('(this.customerSetting',this.customerSetting);
        // this.GenericLogin('gowsick.s@inforios.com')
        
        // if (this.msal.getAllAccounts()) {
        //     console.log('getAllAccounts()####',this.msal.getAllAccounts());
        // }
        
        if (this.msal) {
            try {
                const response = await this.msal.handleRedirectPromise();
                if (response) {
                    console.log('Authentication successful:', response);
                    this.$store.commit("sessionStorage/assignuserLoginSuccessfull", true);
                     // Extract user info from the ID token
                        const userid = response.account?.localAccountId;
                        console.log('userid###',userid);
                        await this.GenericLogin(userid)
                        // this.$store.commit("sessionStorage/assigncustomerID", customerId);
                        // var url = `/${customerId}/shop/sidebar/list?lang=en`
                        // window.location.replace(url)
                        // const phoneNumber = idTokenClaims.extension_phone_number;
                        
                }else{
                    this.azureloader = false                }
            } catch (error) {
                this.azureloader = false
                console.error('Error handling redirect:', error);
            }
        } else {
            this.azureloader = false
        }

        console.log('this.radiooptions', this.radiooptions);
        var color_code = '#000'
        console.log('color_code', color_code);
        this.changeTheme(color_code);

        console.log('MerchantSettings###', this.merchantSettings);
        var default_code = this.merchantSettings.default_lang;
        console.log('default_code', default_code);
        if (default_code !== '') {
            if (default_code === 'ja') {
                this.async_locale = 'ja'
            }
            if (default_code === 'kr') {
                this.async_locale = 'kr'
            }
            if (default_code === 'th') {
                this.async_locale = 'th'
            }
            if (default_code === 'id') {
                this.async_locale = 'id'
            }
            if (default_code === 'zh_Hant') {
                this.async_locale = 'zh_Hant'
            }
            if (default_code === 'zh_Hans') {
                this.async_locale = 'zh_Hans'
            }
            if (default_code === 'en') {
                this.async_locale = 'en'
            }
        }

        var lang_code = this.$store.state.sessionStorage.select_lang;
        if (lang_code !== '') {
            if (lang_code === 'ja') {
                this.async_locale = 'ja'
            }
            if (lang_code === 'kr') {
                this.async_locale = 'kr'
            }
            if (lang_code === 'th') {
                this.async_locale = 'th'
            }
            if (lang_code === 'id') {
                this.async_locale = 'id'
            }
            if (lang_code === 'zh_Hant') {
                this.async_locale = 'zh_Hant'
            }
            if (lang_code === 'zh_Hans') {
                this.async_locale = 'zh_Hans'
            }
            if (lang_code === 'en') {
                this.async_locale = 'en'
            }
        }
         //<<<<<<<<<<<< function for multilang >>>>>>>>>>>>>
        var lang_code = this.async_locale;
        console.log('lang_code123', lang_code);
        if (lang_code !== '' && lang_code !== undefined) {
            this.MultiLang(lang_code)

        }
        this.$store.commit('sessionStorage/assignLang', this.async_locale);

        console.log('created Lang:', this.$store.state.sessionStorage.lang)
        this.$i18n.locale = this.$store.state.sessionStorage.lang;
        this.tabsData[0].title = `${this.$t('page.login.welcome_signIn')} ${this.merchantDetails ? this.merchantDetails.merchant_name : ''} <br> ${this.$t('page.login.digital_wallet')} ${this.$t('page.login.please_login')}`;
        this.radiooptions[0].text = this.$t('page.login.Email');
        this.radiooptions[1].text = this.$t('page.login.Mobile');
        // this.$router.push({ path: this.$route.path, query: { lang: this.$i18n.locale, catalogue_code: this.catalogue_code } });

    }

};
</script>

<style>
:root {
    --primaryColor: #000;
}

.nav-item .nav-link.active {
    color: var(--primaryColor) !important;
    border-bottom-color: var(--primaryColor) !important;
}

.nav-item .nav-link:hover {
    color: var(--primaryColor) !important;
}

.btn-outline-primary-2 {
    color: var(--primaryColor) !important;
    border-color: var(--primaryColor) !important;
}

.btn-outline-primary-2:hover {
    color: #fff !important;
    background-color: var(--primaryColor) !important;
}

.btn-outline-primary-2:focus {
    color: #fff !important;
    background-color: var(--primaryColor) !important;
}

.custom-control.custom-radio .custom-control-input:checked~.custom-control-label::before {
    border-color: var(--primaryColor) !important;
}

.custom-control.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-color: var(--primaryColor) !important;
}

input:focus {
    border-color: var(--primaryColor) !important;
}

.forgot-link:hover {
    color: var(--primaryColor) !important;
}

.privacy-link:hover {
    color: var(--primaryColor) !important;
}

.policy-url {
    color: var(--primaryColor) !important;
    box-shadow: none !important;
}

/* .nav.nav-pills .nav-item .nav-link.active{
    color: #0f0f0e !important;
    border-bottom-color: #0a0a0a;
} */
.date-picker.form-control--error {
    border-color: red !important;
}

.vue-tel-input.form-control--error {
    border-color: red !important;
}

input.form-control--error {
    border-color: red !important;
}

select.form-control--error {
    border-color: red !important;
}

.picker.form-control--error {
    border-color: red !important;
}

.error-txt {
    color: red !important;
    font-size: 14px;
}

.mx-datepicker {
    position: relative !important;
    display: inline-block;
    width: 100% !important;
}

.mx-input {
    border-radius: 3px !important;
    height: 40px !important;
}

.mx-datepicker svg {
    display: none !important;
}

.mx-datepicker {
    width: 100%
}

.mx-calendar.mx-calendar-panel-month .mx-calendar-header {
    display: none;
}

button.mx-btn.mx-btn-text.mx-btn-icon-double-right,
button.mx-btn.mx-btn-text.mx-btn-icon-double-left,
button.mx-btn.mx-btn-text.mx-btn-current-year {
    display: none;
}

.breadcrumb-item+.breadcrumb-item:before {
    font-size: 1.5rem !important;
    margin-top: 0rem !important;
}
@media only screen and  (max-width: 990px) {
    .form-box {
    box-shadow: 0 0px 0px !important;
}
}

/* remove search icon end */

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #7F7E7A;
}

.divider:not(:empty)::before {
  margin-right: .25em;
}

.divider:not(:empty)::after {
  margin-left: .25em;
}

.google-button {
  /* align-items: center; */
  padding: 8.5px 20px;
  font-size: 14px;
  font-weight: 600;
  /* color: var(--primaryColor) !important; */
  background-color: transparent;
  border: 1px solid #8E8074 !important;
  cursor: pointer;
  text-align: center;
  /* text-transform: uppercase; */
}

.google-button:hover {
  /* background-color: var(--primaryColor) !important; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* color:#fff !important */
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
background: #fff;
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(242, 130, 2, 0.75) 1.5em 0 0 0, rgba(242, 130, 2, 0.75) 1.1em 1.1em 0 0, rgba(242, 130, 2, 0.75) 0 1.5em 0 0, rgba(242, 130, 2, 0.75) -1.1em 1.1em 0 0, rgba(242, 130, 2, 0.75) -1.5em 0 0 0, rgba(242, 130, 2, 0.75) -1.1em -1.1em 0 0, rgba(242, 130, 2, 0.75) 0 -1.5em 0 0, rgba(242, 130, 2, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(242, 130, 2, 0.75) 1.5em 0 0 0, rgba(242, 130, 2, 0.75) 1.1em 1.1em 0 0, rgba(242, 130, 2, 0.75) 0 1.5em 0 0, rgba(242, 130, 2, 0.75) -1.1em 1.1em 0 0, rgba(242, 130, 2, 0.75) -1.5em 0 0 0, rgba(242, 130, 2, 0.75) -1.1em -1.1em 0 0, rgba(242, 130, 2, 0.75) 0 -1.5em 0 0, rgba(242, 130, 2, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
